import { defineStore } from "pinia";
import { arrayContains } from "../helpers/helpers";

const skins = {
    ac: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        regulatedBy: "Curaçao Gaming Control Board",
        licenceNumber: "(OGL/2024/1368/0895)",
        originalDomain: "www.amoncasino.com",
        originalName: "Amon Casino",
        coolName: "Amon",
        complaintsEmail: "complaints@amoncasino.com",
        privacyEmail: "dataprivacy@amoncasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 12,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "zpo2favh", supportEmail: "support@amoncasino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "ob5urvu1", supportEmail: "serviceclient@amoncasino.com" },
            // { code: "br", strapiCode: "pt-BR", language: "Português", defaultChatButtonId: "j9f78zwv", supportEmail: "support@amoncasino.com" },
            { code: "pl", strapiCode: "pl", language: "Polski", defaultChatButtonId: "bxug26d9", supportEmail: "obslugaklienta@amoncasino.com" },
            // { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "lesqjw8z", supportEmail: "asiakastuki@amoncasino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "zmey5agw", supportEmail: "kundeservice@amoncasino.com" },
        ],
        // If we have a defaultCurrency, all users from that country will be registered with that defaultCurrency - ELSE EUR
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            // { code: "br", defaultCurrency: "BRL" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
            // { code: "gb", defaultCurrency: "GBP" },
        ],
        pixGamingScript: "pix_amoncasino",
        blueMediaCampaignId: "828B836D-A899-2267-AC712FCCA4971DE7",
        hotjarSkinId: "3462093",
        welcomeOfferVideo: "", //WelcomeOfferVideo.mp4
        welcomeOffer: "Bottom", //RegisterForm, Left, Center, Bottom
        homeLobbyStyle: "netflix", // netflix, simple
        // vipPage: {
        //     view: "vip-path-view", // vip-path-view, vip-column-view
        //     levels: [],
        // },
        headerType: "button", //button, icon
        modal: "middle", // middle, right
        gameThumbnailGrid: "normal", // small, normal
        gameThumbnailSize: "500-700", // 280-280, 500-700, 512-288
        thumbnailDetails: "inside", // inside, outside
        home: "one", // one, two, splash
        paymentsLayout: "row", // row, tile, rowTwo
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "", description: "", titleKey: "AMON_SELLING_1", descriptionKey: "AMON_DESCRIPTION_1" },
            { svg: "fa-2", title: "", description: "", titleKey: "AMON_SELLING_2", descriptionKey: "AMON_DESCRIPTION_2" },
            { svg: "fa-3", title: "", description: "", titleKey: "AMON_SELLING_3", descriptionKey: "AMON_DESCRIPTION_3" },
            { svg: "fa-4", title: "", description: "", titleKey: "AMON_SELLING_4", descriptionKey: "AMON_DESCRIPTION_4" },
        ],
        supportPointsBlocks: [],
        liveAgentId: "pbsupport",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        // QUICKSPIN
        // gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: "https://www.purplebayaffiliates.com/",
        promoThumbsWithDetails: "detailsBelow", // noDetails, detailsBelow, detailsRight
        formStyle: "old", // old, modern
        bannerRotator: "coverflow", // coverflow, slide
        wheelOfFortune: null,
        wheelOfFortune: null,
        gamesDisplay: "lobbies", // lobbies, categories
    },
    ra: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        regulatedBy: "Curaçao Gaming Control Board",
        licenceNumber: "(OGL/2024/1368/0895)",
        originalDomain: "www.racasino.com",
        originalName: "Ra Casino",
        coolName: "Ra",
        complaintsEmail: "complaints@racasino.com",
        privacyEmail: "dataprivacy@racasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 6,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@racasino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "ln99pmwz", supportEmail: "serviceclient@racasino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "b34sdtah", supportEmail: "kundeservice@racasino.com" },
            { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "jxe1x16g", supportEmail: "asiakastuki@racasino.com" },
        ],
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
        ],
        // pixGamingScript: "",
        // blueMediaCampaignId: "",
        hotjarSkinId: "3694857",
        welcomeOfferVideo: "WelcomeOfferVideo.mp4",
        welcomeOffer: "Center",
        homeLobbyStyle: "simple",
        // vipPage: {
        //     view: "vip-path-view",
        //     levels: [],
        // },
        headerType: "icon",
        modal: "right",
        gameThumbnailGrid: "small",
        gameThumbnailSize: "500-700",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentId: "pbsupport",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: "https://www.purplebayaffiliates.com/",
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "slide",
        wheelOfFortune: null,
        gamesDisplay: "lobbies",
    },
    bo: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "https://d2644bed-8b7e-48cf-9609-e7301e31f096.snippet.anjouangaming.org/anj-seal.js",
        companyName: "Elite Cyber Services Limited",
        licenceCountry: "Belize",
        registrationNumber: "177058",
        registrationAddress: "123 Barrack Road, Belize City, Belize",
        regulatedBy: "Union Of Comoros",
        licenceNumber: "ALSI-012401013-F12",
        originalDomain: "www.bofcasino.com",
        originalName: "Bof Casino",
        coolName: "Bof",
        complaintsEmail: "complaints@bofcasino.com",
        privacyEmail: "dataprivacy@bofcasino.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 6,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "2labgla5", supportEmail: "support@bofcasino.com" }],
        countries: [
            { code: "gb", defaultCurrency: "GBP" },
            { code: "au", defaultCurrency: "AUD" },
        ],
        pixGamingScript: "pix_bofcasino",
        // blueMediaCampaignId: "",
        hotjarSkinId: "3815736",
        welcomeOfferVideo: "",
        // welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: {
        //     view: "vip-path-view",
        //     levels: [],
        // },
        // headerType: "button",
        modal: "middle",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "outside",
        home: "two",
        paymentsLayout: "tile",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "Receive 100% up to [[100€]]", description: "on your first deposit", titleKey: "", descriptionKey: "" },
            { svg: "fa-2", title: "Receive 50% up to [[200€]]", description: "on your 2nd deposit", titleKey: "", descriptionKey: "" },
            { svg: "fa-3", title: "Receive 100% up to [[200€]]", description: "on you 3rd deposit", titleKey: "", descriptionKey: "" },
        ],
        supportPointsBlocks: [
            { svg: "fa-solid fa-clock-rotate-left", title: "24/7 support", description: "Round the clock support via email and Live Chat", titleKey: "", descriptionKey: "" },
            { svg: "fa-solid fa-game-console-handheld", title: "1700+ games", description: "From a large variety of providers", titleKey: "", descriptionKey: "" },
            { svg: "fa-solid fa-messages-question", title: "For any questions", description: "We assist with payment issues, registrations and more", titleKey: "", descriptionKey: "" },
        ],
        liveAgentId: "hgr",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "BANK_CITIZEN"],
        affiliateProgram: "https://www.cybereliteaffiliates.com/",
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
        gamesDisplay: "lobbies",
    },
    zu: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "ILP Trading B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "164645",
        registrationAddress: "Schottegatweg Oost 10, Unit 1 - 9 Bon Bini Business Center, Willemstad, Curaçao",
        regulatedBy: "Curaçao Gaming Control Board",
        licenceNumber: "(OGL/2024/1119/0593)",
        originalDomain: "www.zumibet.com",
        originalName: "Zumi Bet",
        coolName: "Zumi",
        complaintsEmail: "complaints@zumibet.com",
        privacyEmail: "dataprivacy@zumibet.com",
        autoExclusionOptions: ["P3M", "P5Y"],
        homeLobbyGames: 6,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" },
            { code: "it", strapiCode: "it", language: "Italiano", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" },
            { code: "de", strapiCode: "de", language: "Deutsch", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" },
            { code: "es", strapiCode: "es", language: "Español", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" },
        ],
        countries: [{ code: "gb", defaultCurrency: "GBP" }],
        // pixGamingScript: "",
        // blueMediaCampaignId: "",
        // hotjarSkinId: "3462093",
        welcomeOfferVideo: "",
        welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: {
        //     view: "vip-path-view",
        //     levels: [],
        // },
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "280-280",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentId: "pbsupport",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsRight",
        formStyle: "modern",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
        gamesDisplay: "lobbies",
    },
    lj: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "https://3c963b50-2987-4df7-a1af-dc9811df5abc.snippet.anjouangaming.org/anj-seal.js",
        companyName: "Prime Web OPS SRL",
        licenceCountry: "Costa Rica",
        registrationNumber: "3-102-904748",
        registrationAddress: "PROVINCE 01 SAN JOSE, CANTON 08 GOICOECHEA, CALLE BLANCOS, MONTELIMAR, Costa Rica",
        regulatedBy: "The Autonomous Island of Anjouan, Union of Comoros",
        licenceNumber: "ALSI-202408038-FI2",
        originalDomain: "www.letsjackpot.com",
        originalName: "Lets Jackpot",
        coolName: "LetsJackpot",
        complaintsEmail: "complaints@letsjackpot.com",
        privacyEmail: "dataprivacy@letsjackpot.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 12,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "7nx9munf", supportEmail: "support@letsjackpot.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "auzqt9y5", supportEmail: "support@letsjackpot.com" },
        ],
        // If we have a defaultCurrency, all users from that country will be registered with that defaultCurrency
        countries: [{ code: "gb", defaultCurrency: "GBP" }],
        // pixGamingScript: "",
        // blueMediaCampaignId: "",
        hotjarSkinId: "5071153",
        welcomeOfferVideo: "",
        welcomeOffer: "Bottom",
        homeLobbyStyle: "netflix",
        // vipPage: {
        //     view: "vip-path-view",
        //     levels: [],
        // },
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "outside",
        home: "one",
        paymentsLayout: "row",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "", description: "", titleKey: "LJ_SELLING_1", descriptionKey: "LJ_DESCRIPTION_1" },
            { svg: "fa-2", title: "", description: "", titleKey: "LJ_SELLING_2", descriptionKey: "LJ_DESCRIPTION_2" },
            { svg: "fa-3", title: "", description: "", titleKey: "LJ_SELLING_3", descriptionKey: "LJ_DESCRIPTION_3" },
        ],
        supportPointsBlocks: [],
        liveAgentId: "hgr",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: "https://www.primewebaffiliates.com/",
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "coverflow",
        wheelOfFortune: {
            wheelName: "LETS JACKPOT WHEEL",
            wheelRotationRandomizr: { min: 10, max: 40 },
        },
        gamesDisplay: "lobbies",
    },
    bz: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "Purple Bay B.V.",
        licenceCountry: "Curaçao",
        registrationNumber: "157944(0)",
        registrationAddress: "Korporaalweg 10, Willemstad, Curaçao",
        regulatedBy: "Curaçao Gaming Control Board",
        licenceNumber: "(OGL/2024/1368/0895)",
        originalDomain: "www.betzino.com",
        originalName: "Betzino",
        coolName: "Betzino",
        complaintsEmail: "complaints@betzino.com", // To CHeck
        privacyEmail: "dataprivacy@betzino.com", // To CHeck
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 20,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "wjpv2j2v", supportEmail: "support@betzino.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "24aj8h88", supportEmail: "serviceclient@betzino.com" },
            { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "g05vcp3q", supportEmail: "kundeservice@betzino.com" },
            { code: "br", strapiCode: "pt-BR", language: "Português", defaultChatButtonId: "r73xyqtv", supportEmail: "apoiar@betzino.com" },
            { code: "pl", strapiCode: "pl", language: "Polski", defaultChatButtonId: "z3pzux2u", supportEmail: "obslugaklienta@betzino.com" },
        ],
        countries: [
            { code: "ca", defaultCurrency: "CAD" },
            { code: "br", defaultCurrency: "BRL" },
            { code: "no", defaultCurrency: "NOK" },
            { code: "pl", defaultCurrency: "PLN" },
        ],
        // pixGamingScript: "",
        blueMediaCampaignId: "4C86B8E1-A8C3-13D7-48FE61C318A59349",
        hotjarSkinId: "1874368",
        welcomeOfferVideo: "",
        welcomeOffer: "Bottom",
        homeLobbyStyle: "netflix",
        vipPage: {
            view: "vip-column-view",
            levels: [
                { name: "standard", campaignId: "6As0BWe48hSyRjBnmYuL" },
                { name: "bronze", campaignId: "nS2M3IG1lgIkB4bumhH9" },
                { name: "silver", campaignId: "6GqICfLim1d7sCpFAofx" },
                { name: "gold", campaignId: "1RYP6ws3FdRps9meQvM0" },
                { name: "platinum", campaignId: "pTnNpCpNT1fHskfXJ879" },
            ],
        },
        headerType: "icon",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "row",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentId: "pbsupport",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: "https://www.purplebayaffiliates.com/",
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "slide",
        wheelOfFortune: null,
        gamesDisplay: "categories",
    },
    nw: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "***************",
        licenceCountry: "***************",
        registrationNumber: "***************",
        registrationAddress: "***************",
        regulatedBy: "***************",
        licenceNumber: "***************",
        originalDomain: "www.newwwwwwwww.com",
        originalName: "Newwww",
        coolName: "New",
        complaintsEmail: "complaints@new.com",
        privacyEmail: "dataprivacy@new.com",
        autoExclusionOptions: ["P3M", "P5Y"],
        homeLobbyGames: 6,
        languages: [{ code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "053hftk4", supportEmail: "support@zumibet.com" }],
        countries: [{ code: "gb", defaultCurrency: "GBP" }],
        // pixGamingScript: "",
        // blueMediaCampaignId: "",
        // hotjarSkinId: "3462093",
        welcomeOfferVideo: "",
        welcomeOffer: "RegisterForm",
        homeLobbyStyle: "simple",
        // vipPage: {
        //     view: "vip-path-view",
        //     levels: [],
        // },
        headerType: "button",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "280-280",
        thumbnailDetails: "inside",
        home: "splash",
        paymentsLayout: "row",
        sellingBlocksStyle: "Two",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentId: "pbsupport",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "PUSH_GAMING", "YGGDRASIL", "PLAYSON", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "VOLTENT", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "BANK_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsRight",
        formStyle: "old",
        bannerRotator: "coverflow",
        wheelOfFortune: null,
        gamesDisplay: "lobbies",
    },
};

// Features
const configPerFeature = {
    isSideMenuLayout: ["bz", "ra"],
    noSideMenu: ["bo"],
    shopFeatureEnabled: ["ac", "ra"],
    showTime: ["nw", "zu"],
    sideMenuBtnInBottomMobileMenu: [],
    allowBonusesInSE: ["ra", "bo", "lj"],
    blockPPGames: ["ac", "ra", "bz", "lj", "bo", "zu"],
    blockEvoPlusGames: ["ac", "ra", "bz", "lj", "bo", "zu"],
    integratedModalBg: ["ra", "bo", "lj"],
    twoFA: ["zu"],
    homePagePromotions: [],
    altenarSportsBook: ["bz"],
};

const isFeatureEnabled = (feature, skin) => {
    return arrayContains(configPerFeature[feature], skin);
};

export const useConfigStore = defineStore("config", {
    state: () => ({
        config: null,
        registerData: null,
        translatedObject: {},
        currentlyTranslatingKeysList: [],
    }),
    actions: {
        initConfig(skin) {
            const initialConfigObject = {
                skin,
                apgSeal: skins[skin].apgSeal,
                xcmSeal: skins[skin].xcmSeal,
                anjSeal: skins[skin].anjSeal,
                originalDomain: skins[skin].originalDomain,
                originalName: skins[skin].originalName,
                coolName: skins[skin].coolName,
                complaintsEmail: skins[skin].complaintsEmail,
                privacyEmail: skins[skin].privacyEmail,
                companyName: skins[skin].companyName,
                licenceCountry: skins[skin].licenceCountry,
                registrationNumber: skins[skin].registrationNumber,
                registrationAddress: skins[skin].registrationAddress,
                regulatedBy: skins[skin].regulatedBy,
                licenceNumber: skins[skin].licenceNumber,
                autoExclusionOptions: skins[skin].autoExclusionOptions,
                homeLobbyGames: skins[skin].homeLobbyGames,
                languages: skins[skin].languages,
                countries: skins[skin].countries,
                pixGamingScript: skins[skin].pixGamingScript,
                hotjarSkinId: skins[skin].hotjarSkinId,
                welcomeOfferVideo: skins[skin].welcomeOfferVideo,
                welcomeOffer: skins[skin].welcomeOffer,
                homeLobbyStyle: skins[skin].homeLobbyStyle,
                vipPage: skins[skin].vipPage,
                modal: skins[skin].modal,
                isSideMenuLayout: isFeatureEnabled("isSideMenuLayout", skin),
                shopFeatureEnabled: isFeatureEnabled("shopFeatureEnabled", skin),
                gameThumbnailGrid: skins[skin].gameThumbnailGrid,
                gameThumbnailSize: skins[skin].gameThumbnailSize,
                thumbnailDetails: skins[skin].thumbnailDetails,
                showTime: isFeatureEnabled("showTime", skin),
                home: skins[skin].home,
                noSideMenu: isFeatureEnabled("noSideMenu", skin),
                sideMenuBtnInBottomMobileMenu: isFeatureEnabled("sideMenuBtnInBottomMobileMenu", skin),
                paymentsLayout: skins[skin].paymentsLayout,
                twoFA: isFeatureEnabled("twoFA", skin),
                sellingBlocksStyle: skins[skin].sellingBlocksStyle,
                sellingPointsBlocks: skins[skin].sellingPointsBlocks,
                supportPointsBlocks: skins[skin].supportPointsBlocks,
                liveAgentId: skins[skin].liveAgentId,
                gameFeatures: skins[skin].gameFeatures,
                gameProviders: skins[skin].gameProviders,
                paymentProviders: skins[skin].paymentProviders,
                headerType: skins[skin].headerType,
                affiliateProgram: skins[skin].affiliateProgram,
                homePagePromotions: isFeatureEnabled("homePagePromotions", skin),
                promoThumbsWithDetails: skins[skin].promoThumbsWithDetails,
                formStyle: skins[skin].formStyle,
                bannerRotator: skins[skin].bannerRotator,
                integratedModalBg: isFeatureEnabled("integratedModalBg", skin),
                blockPPGames: isFeatureEnabled("blockPPGames", skin),
                blockEvoPlusGames: isFeatureEnabled("blockEvoPlusGames", skin),
                wheelOfFortune: skins[skin].wheelOfFortune,
                allowBonusesInSE: isFeatureEnabled("allowBonusesInSE", skin),
                altenarSportsBook: isFeatureEnabled("altenarSportsBook", skin),
                gamesDisplay: skins[skin].gamesDisplay,
                blueMediaCampaignId: skins[skin].blueMediaCampaignId,
            };
            this.config = Object.assign({}, this.config, initialConfigObject);
        },
        addToConfig(newValue) {
            this.config = Object.assign({}, this.config, newValue);
        },
        setRegisterData(newValue) {
            this.registerData = newValue ? Object.assign({}, this.registerData, newValue) : null;
        },
        setCurrentlyTranslatingKeysList(key, action) {
            if (action === "add") {
                this.currentlyTranslatingKeysList = [...this.currentlyTranslatingKeysList, key];
            } else {
                const index = this.currentlyTranslatingKeysList.indexOf(key);
                if (index !== -1) {
                    this.currentlyTranslatingKeysList.splice(index, 1);
                }
            }
        },
        setTranslatedObject(key, text) {
            if (this.translatedObject[key] === undefined) {
                let o = {};
                o[key] = text;
                this.translatedObject = Object.assign({}, this.translatedObject, o);
            }
        },
    },
    persist: true,
});
