<template>
    <div class="reset-password-component mt-3">
        <form>
            <form-field type="password" :v$="v$.password" fieldName="NEW_PASSWORD" autocomplete="new-password" />
            <form-field type="password" :v$="v$.confirmPassword" fieldName="CONFIRM_PASSWORD" autocomplete="new-password" />
            <button type="button" class="btn skin-btn wide mt-4" @click="resetPassword()" :disabled="status === 'PENDING'">
                <font-awesome-icon v-if="status === 'PENDING'" icon="fa-solid fa-spinner" spin pulse />
                <span v-else v-html="getDictionary('RESET_PASSWORD')"></span>
            </button>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, minLength, maxLength, sameAs } from "@vuelidate/validators";
import FormField from "../dynamic/others/formField.vue";
import { resetPassword } from "../../services/tma/axiosTmaRequests";
import { setModalVisibility } from "../../helpers/helpers";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    name: "reset-password-component",
    data: function () {
        return {
            status: "",
            password: null,
            confirmPassword: null,
        };
    },
    validations() {
        return {
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50),
                passwordStrength: function (value) {
                    if (typeof value === "undefined" || value === null || value === "") {
                        return true;
                    }
                    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/.test(value);
                },
            },
            confirmPassword: {
                sameAsPassword: sameAs(this.password),
            },
        };
    },
    created() {
        this.myStore.setModalHeaderTitle("RESET_PASSWORD");
    },
    components: {
        FormField,
    },
    props: {
        hash: String,
    },
    methods: {
        async resetPassword() {
            this.status = "PENDING";
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.status = "";
                return;
            }

            if (this.hash === null) {
                this.myStore.setAlert({ text: "INVALID_HASH", classes: "error" });
            } else {
                resetPassword(this.configStore.config.skin, this.password, this.hash, this.callsStore, this.myStore, this.$router, this.perCallsStore).then((response) => {
                    if (response.success) {
                        setModalVisibility(false, this.$router);
                        this.myStore.setAlert({ text: "SUCCESS_RESET_PASSWORD", classes: "success" });
                        this.status = "SUCCESS";
                    } else {
                        this.status = "FAILED";
                    }
                });
            }
        },
    },
};
</script>
