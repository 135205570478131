<template>
    <div class="payments-component mt-3">
        <dynamic componentPath="loader" folder="others" v-if="status === 'PENDING' || callsStore.realBalanceObject === null"></dynamic>
        <p v-else-if="status === 'NODATA'" class="my-5">
            <strong v-html="getDictionary('NO_DATA')"></strong>
        </p>
        <div v-else-if="type === 'deposit' && callsStore.pendingWithdrawals.length > 0" class="col-sm-6 my-5 m-auto text-start">
            <p class="text-center" v-html="getDictionary('CANCEL_WITHDRAWALS')"></p>
            <pending-withdrawals v-if="myStore.loggedIn" />
        </div>
        <div v-else-if="(type === 'withdraw' && callsStore.userObject.kycProfile.verified === false) || fullPlayerBlock || partialPlayerBlock || autoDetectedPlayerBlock" class="mt-5">
            <span v-html="getDictionary('MISSING_DOCUMENTS')"></span> <router-link to="/account/documents" v-html="getDictionary('HERE')"></router-link> <span v-html="getDictionary('MISSING_DOCUMENTS_2')"></span> <router-link to="/" @click="contactUs()" v-html="getDictionary('HERE')"></router-link>
        </div>
        <div v-else-if="type === 'withdraw' && callsStore.activeBonuses.length > 0" class="mt-5"><span v-html="getDictionary('CANCEL_ACTIVE_BONUSES')"></span> <router-link to="/account/bonuses" v-html="getDictionary('HERE')"></router-link></div>
        <div v-else-if="paymentOptions" :class="{ 'my-row text-center': selectedPayment === null || paymentsLayout !== 'tile' }">
            <template v-for="payment in paymentOptions" :key="`${payment.slug}`" class="row">
                <dynamic v-if="paymentsLayout === 'row'" componentPath="blockStyleRow" folder="paymentsRelated" :payment="payment" :type="type" />
                <dynamic v-if="paymentsLayout === 'rowTwo'" componentPath="blockStyleRowTwo" folder="paymentsRelated" :payment="payment" :type="type" />
                <dynamic v-if="paymentsLayout === 'tile'" componentPath="blockStyleTile" folder="paymentsRelated" :payment="payment" :type="type" :paymentOptions="paymentOptions" />
            </template>
        </div>
    </div>
</template>

<script>
import { getPaymentOptions, getDepositBonuses, getBonuses, paymentConnection, updateUser } from "../../services/tma/axiosTmaRequests";
import { getPaymentInfo, getBlackListedPlayers } from "../../services/strapi/axiosStrapiRequests";
import { getCookie } from "../../helpers/helpers";
import { usePaymentsStore } from "../../store/usePaymentsStore";
import { pushGtmEvent } from "../../helpers/gtm_helpers";
import PendingWithdrawals from "../dynamic/others/pendingWithdrawals.vue";

export default {
    name: "payments-component",
    data: function () {
        return {
            status: "",
            paymentOptions: null,
            existingCard: null,
            fullPlayerBlock: false,
        };
    },
    components: {
        PendingWithdrawals,
    },
    computed: {
        selectedPayment() {
            return usePaymentsStore().selectedPayment;
        },
        paymentsStore() {
            return usePaymentsStore();
        },
        paymentsLayout() {
            return this.configStore.config.paymentsLayout;
        },
        partialPlayerBlock() {
            return this.selectedPayment && (this.selectedPayment.slug === "CARD_PAYMENT_IQ" || this.selectedPayment.slug === "CARD_PAYAGE") && this.myStore.partialPlayerBlock;
        },
        autoDetectedPlayerBlock() {
            return usePaymentsStore().autoDetectedPlayerBlock;
        },
    },
    created() {
        this.myStore.setModalHeaderTitle(this.type);
        this.getPaymentsData(false);
        this.getPaymentsIntervalId = setInterval(
            function () {
                if (this.callsStore.realBalanceObject) {
                    this.getPaymentOptions();
                    clearInterval(this.getPaymentsIntervalId);
                }
            }.bind(this),
            300,
        );
        getDepositBonuses(getCookie("userId"), false, this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
            this.paymentsStore.setBonusCode(this.callsStore.depositBonusesArray.length > 0 ? this.callsStore.depositBonusesArray[0].bonusCode : null);
        });
        // Had to call this to check active bonuses in case of a withdrawal
        getBonuses(getCookie("userId"), false, this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore);

        // Call for BlackListed Players, bonus abusers, etc...
        if (getCookie("depositedCustomer") === "false") {
            getBlackListedPlayers(this.perCallsStore, this.myStore).then((response) => {
                if (response.blackListedPlayersArray && response.blackListedPlayersArray.length > 0) {
                    response.blackListedPlayersArray.map((player) => {
                        if (this.callsStore.userObject && this.callsStore.userObject.email === player.attributes.email) {
                            if (player.attributes.fullBlock) {
                                if (this.callsStore.userObject.displayName.indexOf("BlackListed") === -1) {
                                    const queryInput = `{userId: "${getCookie("userId")}" nickname: "BlackListed-${Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000}"}`;
                                    updateUser(queryInput, this.myStore, getCookie("accessToken"), this.callsStore, this.$router, this.configStore.config.skin, this.perCallsStore);
                                }
                                this.fullPlayerBlock = true;
                            } else if (this.callsStore.userObject.kycProfile.verified === false) {
                                this.myStore.setPartialPlayerBlock(true);
                            }
                        }
                    });
                }
            });
        }
    },
    mounted() {
        if (getCookie("affiliateId") !== "") {
            if (this.type === "deposit") {
                pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Deposit_Opened" });
            } else {
                pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "Withdraw_Opened" });
            }
        }
    },
    props: {
        type: String,
    },
    watch: {
        type: function () {
            this.myStore.setModalHeaderTitle(this.type);
            this.getPaymentOptions();
            this.paymentsStore.setSelectedPayment(null);
        },
        selectedPayment: function () {
            this.paymentsStore.setAccount(null);
            this.paymentsStore.setValidAccount(true);
        },
    },
    unmounted() {
        this.paymentsStore.setSelectedPayment(null);
    },
    methods: {
        getPaymentsData(forceSearch) {
            let query = `userId: "${getCookie("userId")}", orderBy: {field:createdAt, direction:DESCENDING}`;
            paymentConnection(query, forceSearch, this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore);
        },
        contactUs() {
            setTimeout(function () {
                if ($(`#liveAgentButton`).length > 0) {
                    $(`#liveAgentButton`).trigger("click");
                }
            }, 400);
        },
        getPaymentOptions() {
            this.status = "PENDING";

            if (this.type === "deposit" && this.callsStore.depositPaymentOptions.length !== 0) {
                this.getPaymentInfo();
            } else if (this.type === "withdraw" && this.callsStore.withdrawPaymentOptions.length !== 0) {
                this.getPaymentInfo();
            } else {
                let type = this.type === "deposit" ? "DEPOSIT" : "WITHDRAWAL";
                getPaymentOptions(getCookie("userId"), type, this.callsStore.realBalanceObject.currencyCode, getCookie("accessToken"), this.myStore, this.callsStore, this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
                    if (response.success && response.data.length > 0) {
                        this.getPaymentInfo();
                    } else {
                        this.status = "NODATA";
                    }
                });
            }
        },
        getPaymentInfo() {
            getPaymentInfo(this.myStore, this.callsStore, this.configStore.config.skin).then((response) => {
                if (response.length > 0) {
                    this.paymentOptions = this.type === "deposit" ? this.callsStore.fullDepositOptions : this.callsStore.fullWithdrawOptions;
                    setTimeout(
                        function () {
                            this.paymentOptions.some((payment) => {
                                if (payment.slug === this.perCallsStore.lastDepositMethodSlug) {
                                    this.paymentsStore.setSelectedPayment(payment, true);
                                    if (this.paymentsLayout !== "tile" && $(".my-modal").length > 0 && $(`#${payment.slug}`).length > 0) {
                                        $(".my-modal").animate(
                                            {
                                                scrollTop: $(`#${payment.slug}`).offset().top - 130,
                                            },
                                            500,
                                        );
                                    }
                                    return true;
                                }
                            });
                        }.bind(this),
                        300,
                    );
                    this.status = "SUCCESS";
                } else {
                    this.status = "NODATA";
                }
            });
        },
    },
};
</script>
