import { useConfigStore } from "../store/useConfigStore";
import { usePerCallsStore } from "../store/usePerCallsStore";
import { useCallsStore } from "../store/useCallsStore";
import { useMyStore } from "../store/useMyStore";
import { getCookie, contentCurrencyConversion, getUrl, getImageUrl } from "../helpers/helpers";
import { openAiTranslations } from "../services/axiosInstances";

const globalMixin = {
    computed: {
        myStore() {
            return useMyStore();
        },
        configStore() {
            return useConfigStore();
        },
        callsStore() {
            return useCallsStore();
        },
        perCallsStore() {
            return usePerCallsStore();
        },
        translatedObject() {
            return this.configStore.translatedObject;
        },
    },
    watch: {
        translatedObject: function () {
            this.$forceUpdate();
        },
    },
    methods: {
        getUrl(type) {
            return getUrl(type);
        },
        getImageUrl(item) {
            return getImageUrl(item);
        },
        getDictionary(key, actualText, textOnly) {
            let cmsLanguageCode = getCookie("cmsLanguageCode");
            const isOpenAiTranslation = cmsLanguageCode === "it" || cmsLanguageCode === "es" || cmsLanguageCode === "de";
            cmsLanguageCode = isOpenAiTranslation ? "en" : cmsLanguageCode;

            if (key !== null && (key.indexOf("GOODWILL") !== -1 || key.indexOf("CSGW") !== -1)) {
                key = `GOODWILL_${cmsLanguageCode}`.toUpperCase();
            } else if (key !== null) {
                key = `${key}_${cmsLanguageCode}`.toUpperCase().split(" ").join("_");
            }

            let text;
            if (key !== null) {
                text = isOpenAiTranslation && this.configStore.translatedObject[key] ? this.configStore.translatedObject[key] : this.perCallsStore.dictionaryObject[key];
            } else {
                text = actualText;
            }

            if (text) {
                let languageSupportEmail;
                this.configStore.config.languages.some((language) => {
                    if (language.code === getCookie("languageCode")) {
                        languageSupportEmail = language.supportEmail;
                        return true;
                    }
                });
                text = text.split("[SkinSupportEmail]").join(languageSupportEmail);
                text = text.split("[SkinName]").join(this.configStore.config.originalName);
                text = text.split("[SkinComplaintsEmail]").join(this.configStore.config.complaintsEmail);
                text = text.split("[SkinPrivacyEmail]").join(this.configStore.config.privacyEmail);
                text = text.split("[SkinDomain]").join(this.configStore.config.originalDomain);
                text = text.split("[CompanyName]").join(this.configStore.config.companyName);
                text = text.split("[LicenceCountry]").join(this.configStore.config.licenceCountry);
                text = text.split("[RegistrationNumber]").join(this.configStore.config.registrationNumber);
                text = text.split("[RegistrationAddress]").join(this.configStore.config.registrationAddress);
                text = text.split("[RegulatedBy]").join(this.configStore.config.regulatedBy);
                text = text.split("[LicenceNumber]").join(this.configStore.config.licenceNumber);

                // Content Currency Conversion
                if (text.indexOf("[[") !== -1 && text.indexOf("€]]") !== -1) {
                    text = contentCurrencyConversion(text, this.myStore, this.callsStore);
                }

                if (isOpenAiTranslation && key !== null && this.configStore.currentlyTranslatingKeysList.indexOf(key) === -1 && this.configStore.translatedObject[key] === undefined) {
                    this.configStore.setCurrentlyTranslatingKeysList(key, "add");
                    openAiTranslations(key, text, this.configStore, this.myStore);
                    return textOnly ? "LOADING" : "<span class='textLoader'>LOADING</span>";
                } else if (isOpenAiTranslation && key !== null && this.configStore.currentlyTranslatingKeysList.indexOf(key) !== -1) {
                    return textOnly ? "LOADING" : "<span class='textLoader'>LOADING</span>";
                }

                return text;
            } else {
                return `---${key}---`;
            }
        },
    },
};

export default globalMixin;
