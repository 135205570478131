import { defineStore } from "pinia";
import {} from "../helpers/helpers";

export const usePerCallsStore = defineStore("perCalls", {
    state: () => ({
        tmaLobbiesArray: [],
        strapiLobbiesArray: [],
        homeLobbyIdsArray: [],
        menuLobbyIdsArray: [],
        casinoLobbyIdsArray: [],
        liveCasinoLobbyIdsArray: [],
        countriesArray: [],
        dictionaryObject: null,
        unFilteredPromotionsList: [],
        promotionsObject: null,
        pagesObject: null,
        loggedOutCampaignsList: [],
        unFilteredBannerItemsList: [],
        bannerRotatorItemsList: [],
        vipDataObject: null,
        shopItemsArray: [],
        shopItemsDataArray: [],
        websiteBlocksDataArray: [],
        promotePromotionObject: null,
        promoteGameObject: null,
        evolutionGamesArray: [],
        lastDepositMethodSlug: null,
        userActiveBoxesArray: [],
        blackListedPlayersArray: [],
        wheelRewards: [],
    }),
    actions: {
        setTmaLobbiesArray(newValue) {
            this.tmaLobbiesArray = [...newValue];
        },
        setStrapiLobbiesArray(newValue) {
            this.strapiLobbiesArray = [...newValue];
        },
        setHomeLobbyIdsArray(newValue) {
            this.homeLobbyIdsArray = [...newValue];
        },
        setMenuLobbyIdsArray(newValue) {
            this.menuLobbyIdsArray = [...newValue];
        },
        setCasinoLobbyIdsArray(newValue) {
            this.casinoLobbyIdsArray = [...newValue];
        },
        setLiveCasinoLobbyIdsArray(newValue) {
            this.liveCasinoLobbyIdsArray = [...newValue];
        },
        setCountriesArray(newValue) {
            this.countriesArray = [...newValue];
        },
        setDictionaryObject(newValue) {
            this.dictionaryObject = Object.assign({}, newValue);
        },
        setUnFilteredPromotionsList(newValue) {
            this.unFilteredPromotionsList = [...newValue];
        },
        setPromotionsObject(newValue) {
            this.promotionsObject = Object.assign({}, newValue);
        },
        setPagesObject(newValue) {
            this.pagesObject = Object.assign({}, newValue);
        },
        setPagesObject(newValue) {
            this.pagesObject = Object.assign({}, newValue);
        },
        setLoggedOutCampaignsList(newValue) {
            this.loggedOutCampaignsList = [...newValue];
        },
        setUnFilteredBannerItemsList(newValue) {
            this.unFilteredBannerItemsList = [...newValue];
        },
        setBannerRotatorItemsList(newValue) {
            this.bannerRotatorItemsList = [...newValue];
        },
        setVipDataObject(newValue) {
            this.vipDataObject = Object.assign({}, newValue);
        },
        setShopItemsArray(newValue) {
            this.shopItemsArray = [...newValue];
        },
        setShopItemsDataArray(newValue) {
            this.shopItemsDataArray = [...newValue];
        },
        setPromotePromotionObject(newValue) {
            this.promotePromotionObject = Object.assign({}, newValue);
        },
        setPromoteGameObject(newValue) {
            this.promoteGameObject = Object.assign({}, newValue);
        },
        setWebsiteBlocksDataArray(newValue) {
            this.websiteBlocksDataArray = [...newValue];
        },
        setEvolutionGamesArray(newValue) {
            this.evolutionGamesArray = [...newValue];
        },
        setLastDepositMethodSlug(newValue) {
            this.lastDepositMethodSlug = newValue;
        },
        setUserActiveBoxesArray(newValue) {
            this.userActiveBoxesArray = [...newValue];
        },
        setBlackListedPlayersArray(newValue) {
            this.blackListedPlayersArray = [...newValue];
        },
        setWheelRewards(newValue) {
            this.wheelRewards = [...newValue];
        },
    },
    getters: {
        allGamesArray: (state) => {
            let allGamesArray;
            state.tmaLobbiesArray.some((lobby) => {
                if (lobby.id.indexOf("all-games") !== -1) {
                    allGamesArray = lobby.filteredBrandGames;
                    return true;
                }
            });
            return allGamesArray;
        },
        sortedCountriesArray: (state) => {
            return state.countriesArray.sort(function (a, b) {
                if (a.country.name < b.country.name) {
                    return -1;
                }
                if (a.country.name > b.country.name) {
                    return 1;
                }
                return 0;
            });
        },
        footerPagesObject: (state) => {
            let footerPages = {};
            Object.keys(state.pagesObject).forEach((pageKey) => {
                if (state.pagesObject[pageKey].footerLink) {
                    footerPages[pageKey] = state.pagesObject[pageKey];
                }
            });
            return footerPages;
        },
    },
    persist: [
        {
            pick: [
                "strapiLobbiesArray",
                "homeLobbyIdsArray",
                "menuLobbyIdsArray",
                "casinoLobbyIdsArray",
                "liveCasinoLobbyIdsArray",
                "countriesArray",
                "dictionaryObject",
                "unFilteredPromotionsList",
                "promotionsObject",
                "pagesObject",
                "loggedOutCampaignsList",
                "unFilteredBannerItemsList",
                "bannerRotatorItemsList",
                "vipDataObject",
                "shopItemsArray",
                "shopItemsDataArray",
                "websiteBlocksDataArray",
                "promotePromotionObject",
                "promoteGameObject",
                "evolutionGamesArray",
                "lastDepositMethodSlug",
                "userActiveBoxesArray",
                "blackListedPlayersArray",
                "wheelRewards",
            ],
            storage: localStorage,
        },
        {
            pick: ["tmaLobbiesArray"],
            storage: sessionStorage,
        },
    ],
});
